.linechart_container {
    margin: 10px auto 40px;
}

.lineChart_colorScale_container {
    border-radius: 5px;
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    padding: 2px;
}

.linechart_colorScale {
    display: flex;
    height: calc(100% - 4px);
    border-radius: 5px;
    margin: 2px;
    position: relative;
}

.linechart_text_container {
    display: inline-flex;
    flex-direction: row;
    padding: 2px;
    width: 100%;
    text-align: center;
}

.linechart_label {
    margin: 2px;
    font-weight: bold;
}

.linechart_remark {
    margin: 2px;
    font-size: 20px;
    z-index: 1;
    position: absolute;
    display: none;
    top: 35px;
    left: calc(50% - 23px);
    width: max-content;
}

.linechart_colorScale:hover {
    cursor: pointer;
    margin: 0;
    padding: 2px;
}

.linechart_colorScale:hover > p {
   display: block;
}

.linechart-legend-item {
    display: flex;
    flex: 1;
    height: fit-content;
    align-items: center;
    margin: 5px 1%;
    min-width: 235px;
}

.linechart-legend-colorLabel {
    display: inline-block;
    min-width: 20px;
    height: 20px;
    border-radius: 5px;
    margin-right: 10px;
}

.linechart-legend-text {
    margin: 0 0 0 auto;
    font-size: 20px;
}