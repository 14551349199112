.background_text{
  padding:30vh 30vw 30vh 30vw;
  height: 40vh;
  font-size: 4vh;
  background-color: rgba(255,255,255,0.2);
  font-weight: bold;
  margin:0;
  text-align: justify;
}

.new_home_mainContainer {
  width: 100vw;
  height: 100vh;
  background-color: #f7f7f7;
  /* display: inline-flex;
  flex-direction: row; */
}

.menu_container {
  width: 18vw;
  height: 100vh;
  /* padding: 40px 0 125px; */
  border-radius: 0 4.4vh 4.4vh 0;
  background-image: linear-gradient(to top, #679b5a 50%, #191e5a);
  position: absolute;
  left: 0;
  top: 0;
  word-wrap: break-word;
}

.blockContent {
  margin: 1vh 1vw 1vh 1vw;
  color: #797979;
  font-family: PingFangTC;
  display: flex;
  flex-direction: row;
}

.blockContentTitle {
  margin: 20px 1.9vw 20px 1vw;
  font-family: PingFangTC;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #797979;
}

.platform_title {
  /* width: 11.25vw;
  height: 49px; */
  padding-top: 5.3vh;
  padding-left: 3.2vw;
  padding-right: 3.2vw;
  margin: 0;
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  word-break: keep-all;
}

.user_name {
  padding: 30px 0 5px 3.2vw;
  margin: 0;
  font-family: Lato;
  color: #ffffff;
  word-break: keep-all;
  font-size: 18px;
  font-family: NotoSansTC;
  font-weight: bold;
}

.divider {
  width: 2.8vw;
  height: 2px;
  margin: 10px 11.9vw 30px 3.2vw;
  background-color: #ffffff;
}

.menuitem_active {
  cursor: pointer;
  width: auto;
  /* height: 2.6vh; */
  padding: 1.5vh 2.4vw 1.5vh 2.4vw;
  border-width: 0 0 0 0.24vw;
  border-color: #f1cb02;
  border-style: solid;
  background-image: linear-gradient(to right, #7e8eda, rgba(255, 255, 255, 0.13) 91%);
}

.menuitem_inactive {
  cursor: pointer;
  width: auto;
  /* height: 2.6vh; */
  padding: 1.5vh 2.4vw 1.5vh 2.4vw;  
}

/* 
.menuitem_doubleline_active {
  cursor: pointer;
  width: auto;
  height: 2.6vh;
  padding: 0.9vh 2.4vw 2.6vh 2.16vw;
  border-width: 0 0 0 0.24vw;
  border-color: #f1cb02;
  border-style: solid;
  background-image: linear-gradient(to right, #7e8eda, rgba(255, 255, 255, 0.13) 91%);
} */
/* 
.menuitem_doubleline_inactive {
  cursor: pointer;
  width: auto;
  height: 2.6vh;
  padding: 0.9vh 2.4vw 2.6vh 2.4vw;  
} */

.menuitem_text{
  margin: 0;
  width: auto;
  /* height: 20px; */
  font-family: NotoSansTC;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.logout_button {
  cursor: pointer;
  width: 7.8vw;
  height: 2.4vh;
  padding: 1vh 0.72vw;
  margin: 10vh auto 5vh;
  border-radius: 0.8vh;
  border: solid 1px #ffffff;
  /* position: absolute;
  bottom: 10vh;
  left: 2.4vw; */
}

.logout_text{
  width: auto;
  font-family: NotoSansTC;
  font-size: 1.86vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.subMenu_container {
  width: 29.6vw;
  height: 100vh;
  margin: 0 1px;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #f7f7f7;
  /* position: relative;
  z-index: 2; */
}

.page_container{
  width: 78vw;
  height: 100vh;
  padding: 0 2vw;
  position: absolute;
  top: 0;
  right: 0;
  overflow-y: scroll;
}

.page_BoardReport_container{
  width: 79.2vw;
  height: 100vh;
  margin: 0 0.8vw 0 2vw;
  position: absolute;
  top: 0;
  right: 0;
  overflow-y: scroll;
}

.page_title_container {
  height: 38px;
  /* width: 72vw; */
  margin: 13.3px 0 20px 0;
  padding: 22.6px 1.92vw 17.3px 1.92vw;
  border-radius: 2px;
  box-shadow: 2px 2px 2px 0 rgba(121, 121, 121, 0.2);
  background-color: #ffffff;
}

.page_subtitle_container {
  height: 3vh;
  /* width: 72vw; */
  margin: 1vh 0;
  padding: 1.5vh 1.2vw;
  border-radius: 2px;
  box-shadow: 2px 2px 2px 0 rgba(121, 121, 121, 0.2);
  background-color: #ffffff;
}

.page_title_text{
  height: 2.9vw;
  font-family: NotoSansTC;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #353d6a;
  margin: 0;
}

.page_subtitle_text{
  height: 3vw;
  font-family: NotoSansTC;
  font-size: 2.5vh;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
  margin: 0;
}

.process_button_container{
  cursor: pointer;
  width: 9.12vw;
  height: auto;
  padding: 3.6vh 1.92vw 3.6vh 1.2vw;
  border-radius: 2px;
  box-shadow: 2px 2px 2px 0 rgba(121, 121, 121, 0.2);
  background-image: linear-gradient(to bottom, #353d6a, #2d2f3a);
}

.process_button_text{
  width: 5.76vw;
  height: 3.6vh;
  font-family: NotoSansTC;
  font-size: 2.3vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f1cb02;
  float: left;
  margin: 0.25vw 0 0 0.8vw;
}

.iconfilter_B_32 {
  width: 4vh;
  height: 4vh;
  object-fit: contain;
  float: left;
}

.dataupload_button_container{
  cursor: pointer;
  width: 9.12vw;
  height: auto;
  padding: 3.6vh 1.92vw 3.6vh 1.2vw;
  margin: 0 1.2vw;
  border-radius: 2px;
  box-shadow: 2px 2px 2px 0 rgba(121, 121, 121, 0.2);
  background-color: #ffffff;
}

.dataupload_button_text{
  width: 5.76vw;
  height: 3.6vh;
  margin: 0.25vw 0 0 0.8vw;
  font-family: NotoSansTC;
  font-size: 2.3vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #353d6a;
  float: left;
}

.iconic_01_18 {
  width: 4vh;
  height: 4vh;
  object-fit: contain;
  float: left;
}

.condition_container{
  width: 50.9vw;
  height: auto;
  border-radius: 2px;
  box-shadow: 2px 2px 2px 0 rgba(121, 121, 121, 0.2);
  background-color: #ffffff;
  display: inline-flex;
}

.condition_text{
  width: 4.9vw;
  height: 1.6vw;
  margin: 4.13vh 1.7vw;
  font-family: NotoSansTC;
  font-size: 2vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #353d6a;
}

.condition_item{
  padding: 1.5vh 2.72vw 1.5vh 1.92vw;
  width: 38.3vw;
  height: 7.8vh;
}

.display_container{
  margin: 2.4vh 0 3.4vh 0;
  height: auto;
  /* width: 78vw; */
}

.display_header_container{
  display: inline-flex;
  border-bottom: solid 3px #353d6a;
  width: 100%;
  height: 50px;
}

.display_header_item_inavailable{
  cursor: default;
  width: fit-content;
  height: 32px;
  padding: 6px 2vw;
  background-color: lightgray;
  border-top: solid 6px ghostwhite;
  margin-right: 3px;
}

.display_header_item_inactive{
  cursor: pointer;
  width: fit-content;
  height: 34px;
  padding: 10px 2vw 6px 2vw;
  background-color: #ffffff;
  margin-right: 3px;
}

.display_header_item_active{
  cursor: pointer;
  width: fit-content;
  height: 32px;
  padding: 6px 2vw;
  background-color: #353d6a;
  border-top: solid 6px #f1cb02;
  margin-right: 3px;
}

.display_header_item_inavailable_text{
  cursor: default;
  font-family: NotoSansTC;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: gray;
  margin: 0.3vh 0 0 0;
}

.display_header_item_inactive_text{
  font-family: NotoSansTC;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2a2a2a;
  margin: 0.3vh 0 0 0;
}

.display_header_item_active_text{
  font-family: NotoSansTC;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin: 0.3vh 0 0 0;
}

.display_header_item_text{
  width: 8vw;
  text-align: center;
}

.display_body_container{
  /* width: 72vw; */
  /* height: 96.4vh; */
  padding: 2.4vh 1.92vw 3.7vh;
  border-radius: 2px;
  box-shadow: 2px 2px 2px 0 rgba(121, 121, 121, 0.2);
  background-color: #ffffff;
}

.closeSubMenu_button{
  cursor: pointer;
  width: 2.86vh;
  height: 2.86vh;
  object-fit: contain;
  margin: 3vh auto 2vh 25vw;
}

.process_top_container{
  width: 29.6vw;
  height: 36.3vh;
  margin: 0;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
}

.process_bottom_container{
  width: auto;
  height: auto;
  margin: 0;
  padding: 2.26vh 2.32vw 2.26vh 6.08vw;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #353d6a;
  display: inline-flex;
  flex-direction: row;
}

.shellSteps_subtitle{
  width: auto;
  height: 2.6vh;
  margin: 2.6vh auto 2vh 0;
  font-family: NotoSansTC;
  font-size: 1.9vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;
}

.ShellSteps_container {
  width: 24.2vw;
  margin: 0 auto 5.2vh 5.4vw ;
  height: 49.2vh;
  overflow: scroll;
}

.process_bottom_button_text{
  width: auto;
  height: 2.6vh;
  font-family: NotoSansTC;
  font-size: 1.86vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.datalist_top_container{
  width: 29.6vw;
  height: 19.3vh;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
}

.datalist_middle_container {
  width: 21.7vw;
  margin: 0 auto 5.2vh 5.4vw ;
  height: 65.9vh;
  overflow: scroll;
  display: inline-block;
  flex-direction: column;
}

.datalist_subtitle{
  width: auto;
  height: 2.6vh;
  margin: 2.6vh auto 2vh 0;
  font-family: NotoSansTC;
  font-size: 1.9vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;
}

.datalistItem_container {  
  height: 2.3vh;
  padding: 0.5vh 0.6vw 0.3vh 0.8vw;
  margin-bottom: 1.86vh;
  border-radius: 1.7vh;
  border: solid 1px #939393;
  display: inline-flex;
  flex-direction: row;
}

.datalistItem_text{
  width: auto;
  height: auto;
  margin: 0 34px 0 0;
  font-family: NotoSansTC;
  font-size: 1.5vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;
}

.datalistItem_delete_button{
  width: 1.86vh;
  height: 1.86vh;
  margin-top: 0.1vh;
  object-fit: contain;
}

.datalistItem_container:hover{
  height: 2.3vh;
  padding: 0.5vh 0.6vw 0.3vh 0.8vw;
  margin-bottom: 1.86vh;
  border-radius: 1.7vh;
  display: inline-flex;
  flex-direction: row;
  border: solid 1px #679b5a;
  background-color: #353d6a;
}

.datalistItem_container:hover > .datalistItem_text{
  color:#ffffff;
}

.datalistItem_container:hover > .datalistItem_delete_button{
  color:#ffffff;
}

.datalist_bottom_container{
  width: 9.6vw;
  height: 4.78vh;
  margin: 0;
  padding: 2.26vh 2.32vw 2.26vh 17.68vw;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: #353d6a;
  display: inline-flex;
  flex-direction: row;
}

.condition_item_container{
  width: 38.6vw;
  height: 7.86vh;
  display: inline-block;
  overflow: scroll;
  padding: 1.46vh 2.32vw 1.46vh 1.92vw ;
}

.conditionItem_wrapper{
  width: fit-content;
  height: 2.46vh;
  padding: 0.4vh 0 0 0;
  background-color: #ffffff;
  margin: 0 0.4vw 0.93vh 0;
  float: left;
}

.conditionItem_text{
  margin: 0 1vw;
  width: auto;
  height: auto;
  font-family: NotoSansTC;
  font-size: 1.46vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2a2a2a;
}

.login_page_container{
  width: 100vw;
  min-width: 1024px;
  height: 100vh;
  padding: 0;
  background-image: linear-gradient(to top, #679b5a , #656785 );
}

.login_title{
  width: 20vw;
  height: auto;
  margin: 25.6vh 22vw 2vh 58vw;
  font-family: Lato;
  font-size: 3.73vh;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  word-break: keep-all;
}

.login_container{
  width: 20vw;
  /* height: 27.1vh; */
  padding: 5.3vh 22vw 0 58vw;
  margin: 0 
}

.login_forgot_text{
  margin: 1.87vh 7.7vw 5.73vh 6.9vw;
  font-family: NotoSansTC;
  font-size: 1.6vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #939393;
}

.login_button{
  cursor: pointer;
  width: 17.8vw;
  height: 2.7vh;
  margin: 0;
  padding: 0.9vh 1vw 1.2vh;
  border-radius: 0.8vh;
  border: solid 1px #939393;
  background-color: #353d6a;
}

.login_button_text{
  margin: 0;
  font-family: NotoSansTC;
  font-size: 1.86vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.login_copyRight{
  margin: 2.6vh auto 0 35vw;
  font-family: NotoSansTC;
  font-size: 1.6vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #dcdcdc;
}

.login_descr {
  max-width: 780px;
  height: auto;
  padding: 20vh 25vw 5vh;
  margin: auto;
  font-size: 4vh;
  font-weight: bold;
  text-align: justify;
}

.BoardReport_block{
  display: inline-flex;
  border-radius: 2px;
  box-shadow: 2px 2px 2px 0 rgba(121, 121, 121, 0.2);
  background-color: #ffffff;
}

.BoardReport_block_title_text{
  margin: 2vh 0 2vh 1.9vw;
  font-family: PingFangTC;
  font-size: 2.1vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #797979;
}

.BoardReport_block_subtitle_text{
  font-family: PingFangTC;
  font-size: 1.86vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6a6a6a;
}

.BoardReport_block_item_name{
  margin: 0;
  font-family: PingFangTC;
  font-size: 1.86vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 5.3vh;
  letter-spacing: normal;
  color: #6a6a6a;
 }

.BoardReport_block_date_year{
  margin: 1.8vh 2.5vw 0 1.8vw;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  font-family: Roboto;
  font-size: 4.8vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #679b5a;
}

.BoardReport_block_date_period{
  margin: 0 0 0 1.8vw;
  height: 5.2vh;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  font-family: Roboto;
  font-size: 4.8vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #353d6a;
}

.BoardReport_block_promise_year{
  margin: 0 0.8vw 0 0;
  font-family: NotoSansTC;
  font-size: 2.1vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #679b5a;
  float: left;
}

.BoardReport_block_promise_content{
  margin: 0;
  font-family: NotoSansTC;
  font-size: 2.1vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: #3e3e3e;
  float: left;
}

.BoardReport_block_progressRate_number{
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  font-family: NotoSansTC;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.BoardReport_block_progressRate_status{
  float: left;
  padding: 3px 23px;
  border-radius: 15px;
  background-color: #679b5a;
  margin: 0;
  position: relative;
  top: -9vh;
  left: 2.3vw;
  width: max-content;
  font-family: NotoSansTC;
  font-size: 1.73vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.BoardReport_realEstate_item{
  display: inline-flex;
}

.BoardReport_realEstate_item:hover{
  display: inline-flex;
  border-left: solid 3px #f1cb02;
  background-color: rgba(194, 194, 194, 0.09);
}

.BoardReport_block_greenIndustry_item{
  display: inline-flex;
  height: 4.8vh;
}

.BoardReport_block_greenIndustry_Line {
  width: 17.7vw;
  margin: 1.8vh 0.6vw 0 0.4vw;
  border-radius: 1.1vh;
}

.BoardReport_block_greenIndustry_proportion{
  font-family: NotoSansTC;
  font-size: 1.46vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #939393;
}

.BoardReport_block_greenIndustry_value{
  font-family: Roboto;
  font-size: 3.2vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 1vh;
}

.BoardReport_block_greenIndustry_item:hover > .BoardReport_block_greenIndustry_value{
  color: #f1cb02
}

.BoardReport_block_highClimateRiskIndustry_Line{
  width: 17.7vw;
  margin: 0 0.6vw 0 0.4vw;
  float: left;
}

.BoardReport_block_highClimateRiskIndustry_Line_index{
  font-family: NotoSansTC;
  font-size: 1.46vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #939393;
  float: left;
  margin: 0.5vh 0;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  border-color: #979797;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.BoardReport_block_highClimateRiskIndustry_Line_text{
  margin: 0;
  font-family: NotoSansTC;
  font-size: 1.46vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #939393;
  position: absolute;
  top: 5.9vh;
  left: 7.4vw;
}

.BoardReport_highClimateRiskIndustry_alert_text{
  margin: 0 0 0 0.4vw;
  font-family: PingFangTC;
  font-size: 1.46vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #939393;
}

.BoardReport_carbonEmission_item{
  height: 5.3vh;
  display: inline-flex;
}

.BoardReport_carbonEmission_item_name{
  margin: 2.2vh 2.4vw 0 1.92vw ;
  font-family: PingFangTC;
  font-size: 1.86vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #6a6a6a;
}

.BoardReport_carbonEmission_item_value{
  margin: 2.2vh 2.4vw 0 1.92vw ;
  font-family: PingFangTC;
  font-size: 1.86vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #6a6a6a;
}

.display_report_title{
  width: auto;
  margin: 1.2vh auto 1.5vh 1.3vw;
  font-family: PingFangTC;
  font-size: 2.1vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
  color: #07054b;
}

.display_report_block{
  padding-top: 0.4vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  width: 9.5vw;
  height: 3.6vh;
  border:solid 1px #eaeaea;
  margin: 0;
  font-family: Roboto;
  font-size: 1.86vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #07054b;
}

.single_display_report_block{
  padding-top: 0.4vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  width: 15vw;
  /* height: 3.6vh; */
  border:solid 1px #eaeaea;
  margin: 0;
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #07054b;
}

.display_report_description{
  font-family: NotoSansTC;
  font-size: 1.86vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: justify;
  color: #07054b;
}

.display_chart_title{
  margin: 0 auto 1.6vh 1vw;
  font-family: PingFangTC;
  font-size: 2.1vh;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #07054b;
}

.TravelCarbonEmissions_block{
  display: inline-flex;
  border-radius: 2px;
  box-shadow: 2px 2px 2px 0 rgba(121, 121, 121, 0.2);
  background-color: #ffffff;
}

.TravelCarbonEmissions_block_title_text{
  margin: 2vh 0 2vh 1.9vw;
  font-family: PingFangTC;
  font-size: 2.1vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #797979;
}

.TravelCarbonEmissions_block_subtitle_text{
  font-family: PingFangTC;
  font-size: 1.86vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6a6a6a;
}

.deleteReductionRatio_button{
  cursor: pointer;
  width: 2.86vh;
  height: 2.86vh;
  object-fit: contain;
  margin: 2vh auto 0 5vw;
}

.TravelCarbonEmissionsRecordItem_goalBudget{
  font-family: PingFangTC;
  font-size: 3vh;
  text-align: center;
  font-weight: bold;
  width: fit-content;
  margin:1.5vh auto;
  border-radius: 5px;
  padding: 5px;
}

.TravelCarbonEmissionsRecordItem_reductionRatio{
  font-family: PingFangTC;
  text-align: center;
}

.TravelCarbonEmissionsRecordItem_goalType{
  font-family: PingFangTC;
  border-radius: 5px;
  padding:1vh 1vw;
  margin: 0 0.5vw;
}

.TravelCarbonEmissionsRecordItem_p{
  margin: 0;
  text-align: center;
}

.DataDownload_block{
  display: inline-flex;
  border-radius: 2px;
  box-shadow: 2px 2px 2px 0 rgba(121, 121, 121, 0.2);
  background-color: #ffffff;
}

.DataDownload_block_title_text{
  margin: 2vh 1.9vw 2vh 1.9vw;
  font-family: PingFangTC;
  font-size: 2.1vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #797979;
}

.DataDownload_dataType_icon{
  width: 5vh;
  height: 5.5vh;
  margin-right: 5px;
}

.MuiMenuItem-root{
  font-size: 2.5vh;
  line-height: normal;
}

.MuiSelect-icon{
  right: 0;
  top: auto;
}


















.home_mainContainer {
  flex-direction: column;
  display: flex;
  flex: 1;
  background-color: antiquewhite;
  padding: 1% 1% 0 1%;
}

.home_subContainer_1 {
  flex-direction: row;
  display: flex;
  flex: 2;
  margin: 10px 5px 20px 5px ;
}

.home_subContainer_2 {
  flex-direction: row;
  display: flex;
  flex: 8;
  width: 100%;
  height: auto;
  margin: 20px 5px 10px 5px ;
}

.footer_container {
  flex: 2;
  background-color: silver;
  text-align: center;
}

.title {
  flex: auto;
  padding: 5px;
  font-size: 36px;
}

.subtitle {
  text-align: center;
  padding: 5px;
  font-size: 24px;
}

.Display_container {
  flex: 6;
  margin: 0 1% 0 1%;
}

.Display_chart {
  margin: 0 1% 0 1%;
  height: 90%;
  border: orangered;
  border-width: 2px;
  border-radius: 5px;
  border-style: solid;
  background-color: whitesmoke;
}

.DataList_container {
  flex: 2;
  margin: auto;
  width: 93%;
  height: 94%;
  border: green;
  border-width: 2px;
  border-radius: 5px;
  border-style: solid;
  background-color: whitesmoke;
}

.shellSteps_process_index {
  width: 10%;
  font-size: 16px;
  float: left;
  padding: 1%;
  margin-top: 3%;
}

.shellSteps_process_div {
  width: 80.2%;
  border-radius: 3px;
  border-width: 1px;
  border-color: lightgray;
  border-style: solid;
  font-size: 16px;
  float: left;
  padding: 2% 0 2% 4%;
  margin-top: 3%;
}

.DisplayCanvas_container {
  border: black;
  border-width: 2px;
  border-radius: 3px;
  border-style: solid;
  width: 100%;
  height: 88%;
  padding: 1%;
}

.DisplayCanvas_PieChart_title {
  font-size: 18px;
  text-align: center;
  margin: 10px;
}

.DisplayCanvas_PieChart_container {
  float: left;
  width: 25%;
  margin: 10px 0 10px 0;
}

.Datalist_item_index {
  float: left;
  font-size: 16px;
  padding: 2px;
  width: 10%;
}

.Datalist_item_value {
  float: left;
  width: 80%;
}

@media (max-aspect-ratio: 1130/705){
  .process_button_text{
    width: 4.76vw;
    height: 3.6vh;
    font-family: NotoSansTC;
    font-size: 2.3vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f1cb02;
    float: left;
    margin: 0.6vw 0 0 0.8vw;
  }

  .dataupload_button_text{
    width: 4.76vw;
    height: 3.6vh;
    margin: 0.5vw 0 0 0.8vw;
    font-family: NotoSansTC;
    font-size: 2.3vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #353d6a;
    float: left;
  }

  .menuitem_active {
    cursor: pointer;
    width: auto;
    /* height: 2.6vh; */
    padding: 1vh 1.4vw 1vh 2vw;
    border-width: 0 0 0 0.24vw;
    border-color: #f1cb02;
    border-style: solid;
    background-image: linear-gradient(to right, #7e8eda, rgba(255, 255, 255, 0.13) 91%);
  }

  /* .menuitem_doubleline_active {
    cursor: pointer;
    width: auto;
    height: 2.6vh;
    padding: 0.9vh 1.4vw 2.6vh 2.16vw;
    border-width: 0 0 0 0.24vw;
    border-color: #f1cb02;
    border-style: solid;
    background-image: linear-gradient(to right, #7e8eda, rgba(255, 255, 255, 0.13) 91%);
  } */
  
  .menuitem_inactive {
    cursor: pointer;
    width: auto;
    /* height: 2.6vh; */
    padding: 1vh 1.4vw 1vh 2vw;  
  }

  /* .menuitem_doubleline_inactive {
    cursor: pointer;
    width: auto;
    height: 2.6vh;
    padding: 0.9vh 1.4vw 2.6vh 2.4vw;  
  } */

  .BoardReport_block_progressRate_status{
    float: left;
    padding: 3px 23px;
    border-radius: 15px;
    background-color: #679b5a;
    margin: 0;
    position: relative;
    top: -9vh;
    left: 3vw;
    width: max-content;
    font-family: NotoSansTC;
    font-size: 1.73vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .display_report_block{
    padding-top: 0.4vh;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    width: 14.5vw;
    height: 3.6vh;
    border:solid 1px #eaeaea;
    margin: 0;
    font-family: Roboto;
    font-size: 1.86vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #07054b;
  }

  .single_display_report_block{
    padding-top: 0.4vh;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    width: 15vw;
    /* height: 3.6vh; */
    border:solid 1px #eaeaea;
    margin: 0;
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #07054b;
  }

  .BoardReport_block_subtitle_text{
    font-family: PingFangTC;
    font-size: 1.6vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }

  .BoardReport_carbonEmission_item_value{
    margin: 2.2vh 2.4vw 0 1vw ;
    font-family: PingFangTC;
    font-size: 1.86vh;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #6a6a6a;
  }

  .platform_title {
    /* width: 11.25vw;
    height: 49px; */
    padding-left: 2vw;
    padding-right: 2vw;
    margin: 0;
    font-family: Lato;
    font-size: 2.6vh;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    word-break: keep-all;
  }

  .divider {
    margin: 1vh 11.9vw 3vh 2vw;
  }

  .user_name {
    padding: 3vh 0 0.5vh 2vw;
  }
}

@media screen and (max-width: 1024px){
  .login_descr{
    max-width: 515px;
    margin: auto;
  }
}