.tab_container {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}

.chart_container {
    margin: 0;
    flex: 1;
    min-width: 200px;
}