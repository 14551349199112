/* @import url("https://css.gg/chevron-left.css");
@import url("https://css.gg/chevron-right.css");
@import url("https://css.gg/add-r.css");
@import url("https://css.gg/remove-r.css");
@import url("https://css.gg/sort-az.css");
@import url("https://css.gg/sort-za.css"); */

.gg-chevron-right,
.gg-chevron-left {
  /* This value will multiple the actual size  */
  --ggs: 1.5;
  margin: 0 5px;
}

.gg-chevron-right:hover,
.gg-chevron-left:hover {
  color: black;
  cursor: pointer;
}

.gg-add-r,
.gg-remove-r {
  /* This value will multiple the actual size  */
  --ggs: 1;
}

.gg-add-r:hover,
.gg-remove-r:hover {
  /* This value will multiple the actual size  */
  --ggs: 1.2;
}

.gg-sort-az,
.gg-sort-za {
  color: gray;
  --ggs: 1.2;
  margin: auto 5px;
  display: none;
}

.table_title_container:hover .gg-sort-az,
.table_title_container:hover .gg-sort-za {
  display: inline-block;
}

.table_title_container:hover .is_sorting_on {
  color: black;
  display: inline-block;
}

.is_sorting_on {
  color: black;
  display: inline-block;
}

.is_lastPage,
.is_lastPage:hover {
  color: lightgray;
  cursor: initial;
  text-decoration: none;
  font-weight: initial;
}

.creatable_select {
  margin: 2vh 0 0 1vw;
  min-width: 9vw;
}

.creatable_table_container {
  display: flex;
  flex-direction: column;
  background-color: rgb(247, 247, 247);
  border-radius: 5px;
  overflow-x: scroll;
  padding: 30px;
  /* height: -webkit-fill-available; */
  min-height: 39vh;
  max-height: 49vh;
  /* justify-content: center; */
  border: 1px solid black;
}

.table_row_container {
  display: inline-flex;
  border-style: solid;
  width: fit-content;
  text-align: center;
  border-width: 0px;
}

.table_title {
  border-color: rgb(137, 136, 136);
}

.table_inputRow {
  color: rgb(173, 171, 171);
  /* border-color: transparent; */
}

.table_text {
  border: black solid 1px;
  margin: 0;
  padding: 5px;
  /* font-size: 1.5vh; */
  color: rgb(0, 0, 0);
  line-height: 20px;
}

.table_title_container {
  margin: 0;
  padding: 5px;
  background-color: lightgray;
  border: black solid 1px;
  justify-content: space-evenly;
}

.table_title_container:hover {
  cursor: pointer;
}

.table_title_text {
  /* font-size: 1.5vh; */
  color: rgb(0, 0, 0);
  line-height: 20px;
  margin: 0;
}

.table_inputRow_text {
  border-color: rgb(173, 171, 171);
  color: rgb(173, 171, 171);
}

.creatable_table_pageRow,
.creatable_table_buttonRow {
  display: inline-flex;
  align-items: center;
  float: right;
  margin: 0 0 0 20px;
}

.is_thisPage {
  color: #115e95;
  text-decoration: underline;
  font-weight: bold;
}

.creatable_table_page_button {
  margin: 0;
  width: 25px;
  text-align: center;
  font-size: 28px;
  cursor: pointer;
}

.creatable_table_page_button:hover {
  text-decoration: underline;
  font-weight: bold;
}

.creatable_table_bottom {
  height: 80px;
  display: flex;
  flex-direction: row-reverse;
}

.creatable_table_button {
  padding: 10px 20px;
  margin: 0 20px;
  font-size: 18px;
  border: 2px solid gray;
  border-radius: 5px;
  background-color: white;
  font-weight: bold;
}

.creatable_table_button:hover {
  background-color: rgb(87, 111, 199);
  color: white;
  cursor: pointer;
}

.button_disable {
  
}

.is_button_disable {
  color: lightgray;
  border: 2px solid lightgray;
}

.is_button_disable:hover {
  background-color: white;
  color: lightgray;
  cursor: initial;
}

.table_functional_container {
  padding: 2px 10px 0 10px;
  width: min-content;
  text-align: center;
}

.table_newData_button {
  padding: 0;
  margin: 5px auto 0;
  height: 22px;
  text-align: center;
  width: 22px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5vh;
}

.table_checkbox_container {
  text-align: center;
}

.table_new_data_button {
  font-size: 1.5vh;
  padding: 3px;
  margin: 2px;
  width: 34px;
  border: 1px solid lightgray;
  border-radius: 3px;
  color: lightgray;
  text-align: center;
}

.is_new_data_ready {
  border-color: black;
  color: black;
}

.is_new_data_ready:hover {
  background-color: rgb(87, 111, 199);
  color: white;
  border-color: white;
  font-weight: bold;
  cursor: pointer;
}

.table_checkbox {
}

.creatable_table_message_container{
  margin: 0 auto 0 2vw;
  font-size: 24px;
  color: red;
  font-weight: 500;
}

.creatable_table_message {

}