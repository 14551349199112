.remark {
    padding-left: 10px;
    border: black solid 1px;
    border-radius: 5px;
    min-height: 8vh;
    display: block;
}

.remark p {
    margin: 10px 0 0 0;
}

.remark li {
    display: inline-block;
    margin: 10px 0 10px 30px;
}

.EnvVarCDFH_popover_button {
    border: 1px solid #797979;
    color: #797979;
    border-radius: 5px;
    font-size: 18px;
    background-color: white;
    padding: 6px 10px;
    margin: 2vh 0 0 1vw;
}

.EnvVarCDFH_popover_button:hover {
    cursor: pointer;
    color: black;
    border-color: black;
}

.isButtonClicked {
    background-color: rgb(232, 229, 229);
    padding: 5px 9px;
    border: 2px solid #1959ac;
    color: black;
}


.infoMark {
    height: 26px;
    width: 26px;
    font-size:20px;
    margin: auto auto auto 0;
    font-weight: bold;
    border: lightcoral solid 2px;
    color:lightcoral;
    /* display:inline-block; */
    /* display:none; */
    border-radius:10px;
    text-align:center;
    text-decoration:none;
    cursor: context-menu;
}

.infoContent {
    display: none;
}

.infoContent > li {
    /* list-style: none; */
    margin-left: 2vw;
}

.infoContent > li > p {
    display: inline-block;
    margin: 0;
}

.infoMark:hover + .infoContent {
    z-index: 1;
    display: block;
    width: fit-content;
    font-size: 20px;
    padding: 15px;
    color: black;
    font-weight: bold;
    position: absolute;
    left: 15%;
    top: 15vh;
    background-color: #fff4bc;
    border: #797979 solid 2px;
    border-radius: 5px;
}