.label_container {
    display: inline-flex;
    width: calc(100% - 40px);
    justify-content: space-between;
    position: absolute;
    bottom: 30px;
}

.label_text {
    font-size: 20px;
    margin: 0 auto;
    font-weight: 600;
}