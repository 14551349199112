.blockContainer {
    display: inline-flex;
    border-radius: 2px;
    box-shadow: 2px 2px 2px 0 rgba(121, 121, 121, 0.2);
    background-color: #ffffff;
    margin-bottom: 2vh;
}

.inputItemContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin: 10px;
    height: 5vh;
}

.inputDataName {
    flex: 1;
    font-size: 2vh;
    color: black;
    position: relative;
}

.inputButton {
    position: absolute;
    display: inline-block;
    flex: 1;
    width: 15vw;
    min-width: 190px;
    font-size: 2.2vh;
    font-weight: bold;
    color: #797979;
    height: 4vh;
    border: 1px solid #aaa;
    border-radius: 3px;
    margin: 2vh 0;
    background-color: #ffffff;
}

.inputButton:hover {
    color: black;
    font-weight: bold;
    background-color: #ccc;
    cursor: pointer;
    border-color: black;
}

input {
    flex: 1;
    margin-right: 30px;
    font-size: 2.2vh;
}

.outputTable-container {
    /* display: inline-block; */
    /* margin: 1vh calc(50% - 30vw); */
    flex-wrap: wrap;
}

.outputTable {
    margin: 2vh 0;
    display: flex;
    flex-direction: row;
    flex: 4;
    border: 1px solid #888;
    font-size: 20px;
    min-width: 400px;
}

.outputTable > div:first-child,
.outputTable-row > p:first-child {
    background-color: #29367e;
    color: white;
    font-weight: bold;
}

.outputTable-row {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.outputTable-row-cell {
    flex: 1;
    min-height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    border: 1px solid #888;
    text-align: center;
}

.outputTable-thermo-row {
    margin: 1vh 0;
    display: flex;
    flex-direction: row;
}

.outputTable-thermo-item {
    display: inline-flex;
    flex: 1;
    justify-content: center;
    margin-left: 15px;
    position: relative;
}

.recordTable-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    border: 1px solid #888;
    text-align: center;
    height: fit-content;
}

.recordTable-row {
    display: flex;
    width: fit-content;
    flex-direction: row;
    flex: 1;
}

.recordTable-row-cell {
    padding: 5px;
    margin: 0;
    border: 1px solid #888;
    text-align: center;
}

.recordTable-container > div:first-child {
    font-size: 18px;
    font-weight: bold;
}

.questionIcon {
    height: 5vh;
    margin-top: 16px;
    background-color: rgb(255, 255, 255, 0.8);
}

.questionMark {
    height: 30px;
    width: 30px;
    font-size:24px;
    margin: 20px 0 0 0;
    font-weight: bold;
    border: #797979 solid 3px;
    color:#797979;
    display:inline-block;
    border-radius:100%;
    text-align:center;
    text-decoration:none;
    cursor: help;
}

.questionInfo {
    display: none;
}

.questionInfo > li {
    list-style: none;
}

.questionMark:hover + .questionInfo {
    z-index: 1;
    display: block;
    width: fit-content;
    font-size: 20px;
    padding: 15px;
    color: black;
    font-weight: bold;
    position: absolute;
    left: 50%;
    top: 5vh;
    background-color: white;
    border: #797979 solid 3px;
    border-radius: 5px;
}

.alertBlock {
    padding: 2vh 1vw;
    display: inline-block;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: red;
}

.alertBox-button {
    margin: 10px 25px;
    width: 8vw;
    font-size: 16px;
    border: #797979 1px solid;
    border-radius: 3px;
    cursor: pointer;
}
.gaugeChart-legend-item {
    display: flex;
    flex: 1;
    height: fit-content;
    align-items: center;
    margin: 5px 0;
}

.gaugeChart-legend-colorLabel {
    display: inline-block;
    min-width: 20px;
    height: 20px;
    border-radius: 5px;
    margin-right: 10px;
}

.gaugeChart-legend-text {
    margin: 0;
    font-size: 20px;
}

@media screen and (max-width: 1300px){

}