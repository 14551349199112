.input_area_container {
    display: inline-flex;
    flex-wrap: wrap;
}

.selector_container {
    margin: 0 1% 3vh 1%;
    height: 8vh;
    min-width: 200px;
}

.subtitle_text {
    font-family: PingFangTC;
    font-size: 1.86vh;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6a6a6a;
    position: relative;
    left: 1.92vw;
    margin: 10px 0;
}

.switch_container {
    margin: 0 2vw;
}

.result_container {
    margin: 4vh 4vw;
    min-width: 700px;
    overflow-x: scroll;
}

.result_table_block{
    padding-top: 0.4vh;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    /* width: 16%; */
    flex: 1;
    min-width: 120px;
    /* height: 3.6vh; */
    border:solid 1px #eaeaea;
    margin: 0;
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: #07054b;
  }